import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import API from '../utils/api';

const ContactPage = () => {
    const [contactInfo, setContactInfo] = useState({
        email: '',
        socialLinks: {
            linkedin: '',
            twitter: '',
            facebook: '',
            github: ''
        }
    });

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const res = await API.get('/user');
                setContactInfo(res.data);
            } catch (error) {
                console.error('Failed to fetch contact info:', error);
            }
        };

        fetchContactInfo();
    }, []);

    return (
        <div className="container contentBodyLightContainer">
            <h1>Contact</h1>
            <p>Email: {contactInfo.email}</p>
            <ul>
                {contactInfo.socialLinks.linkedin && (
                    <li>
                        <a href={contactInfo.socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                        </a>
                    </li>
                )}
                {contactInfo.socialLinks.twitter && (
                    <li>
                        <a href={contactInfo.socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} /> Twitter
                        </a>
                    </li>
                )}
                {contactInfo.socialLinks.facebook && (
                    <li>
                        <a href={contactInfo.socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} /> Facebook
                        </a>
                    </li>
                )}
                {contactInfo.socialLinks.github && (
                    <li>
                        <a href={contactInfo.socialLinks.github} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGithub} /> GitHub
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ContactPage;
