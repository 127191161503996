import React, { useContext } from 'react';
import { Navbar, Nav, Container, NavDropdown, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Header = () => {
    const { user, profile, logout } = useContext(AuthContext);

    return (
        <Navbar fixed="true" bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand as={NavLink} to="/">
                    {profile && profile.fullName ? profile.fullName : 'Anthony Raudino'} <sup className="smallSup">BETA</sup>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/" end>Portfolio</Nav.Link>
                        <Nav.Link as={NavLink} to="/about">About</Nav.Link>
                        <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        {user ? (
                            <NavDropdown title="Dashboard" id="dashboard-dropdown">
                                <NavDropdown.Item as={NavLink} to="/update-profile">Update Profile</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/add-project">Add Project</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <Button variant='outline-light' as={NavLink} to="/login">Login</Button>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
