import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';


const ScrollWrapper = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
      // Trigger a re-render of the Scrollbars component when the location changes
    }, [location]);


  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: '#111',
      borderRadius: '4px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars
      style={{ width: '100%', height: '100vh' }}
      renderThumbVertical={renderThumb}
      renderThumbHorizontal={renderThumb}
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollWrapper;
