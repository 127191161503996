import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import API from '../utils/api';

const DashboardPage = () => {
    const { user, logout } = useContext(AuthContext);
    const [projects, setProjects] = useState([]);
    const [profile, setProfile] = useState({
        bio: '',
        socialLinks: {
            linkedin: '',
            twitter: '',
            facebook: '',
            github: '',
            technologies: ''
        }
    });
    const [newProject, setNewProject] = useState({
        title: '',
        buildYear: '',
        images: '',
        description: '',
        liveLink: '',
        githubLink: '',
        technologies: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileRes = await API.get('/user');
                setProfile(profileRes.data);

                const projectsRes = await API.get('/projects');
                setProjects(projectsRes.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleProjectChange = (e) => {
        const { name, value } = e.target;
        setNewProject(prevProject => ({
            ...prevProject,
            [name]: value
        }));
    };

    const handleProfileSubmit = async (e) => {
        e.preventDefault();
        try {
            await API.put('/user', profile);
            alert('Profile updated successfully');
        } catch (error) {
            alert('Failed to update profile');
        }
    };

    const handleProjectSubmit = async (e) => {
        e.preventDefault();
        try {
            await API.post('/projects', newProject);
            const res = await API.get('/projects');
            setProjects(res.data);
            setNewProject({
                title: '',
                buildYear: '',
                images: '',
                description: '',
                liveLink: '',
                githubLink: '',
                technologies: ''
            });
        } catch (error) {
            alert('Failed to add project');
        }
    };

    return (
        <div className="container contentBodyLightContainer">
            <h1>Dashboard</h1>
            {user && (
                <button className="btn btn-danger" onClick={logout}>Logout</button>
            )}
            <h2>Edit Profile</h2>
            <form onSubmit={handleProfileSubmit}>
                <div className="mb-3">
                    <label htmlFor="bio" className="form-label">Bio</label>
                    <textarea
                        className="form-control"
                        id="bio"
                        name="bio"
                        value={profile.bio}
                        onChange={handleProfileChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="linkedin" className="form-label">LinkedIn</label>
                    <input
                        type="text"
                        className="form-control"
                        id="linkedin"
                        name="socialLinks.linkedin"
                        value={profile.socialLinks.linkedin}
                        onChange={handleProfileChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="twitter" className="form-label">Twitter</label>
                    <input
                        type="text"
                        className="form-control"
                        id="twitter"
                        name="socialLinks.twitter"
                        value={profile.socialLinks.twitter}
                        onChange={handleProfileChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="facebook" className="form-label">Facebook</label>
                    <input
                        type="text"
                        className="form-control"
                        id="facebook"
                        name="socialLinks.facebook"
                        value={profile.socialLinks.facebook}
                        onChange={handleProfileChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="github" className="form-label">GitHub</label>
                    <input
                        type="text"
                        className="form-control"
                        id="github"
                        name="socialLinks.github"
                        value={profile.socialLinks.github}
                        onChange={handleProfileChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Update Profile</button>
            </form>
            <h2>Add Project</h2>
            <form onSubmit={handleProjectSubmit}>
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">Title</label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        value={newProject.title}
                        onChange={handleProjectChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="buildYear" className="form-label">Build Year</label>
                    <input
                        type="number"
                        className="form-control"
                        id="buildYear"
                        name="buildYear"
                        value={newProject.buildYear}
                        onChange={handleProjectChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="images" className="form-label">Images (comma separated URLs)</label>
                    <input
                        type="text"
                        className="form-control"
                        id="images"
                        name="images"
                        value={newProject.images}
                        onChange={handleProjectChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        value={newProject.description}
                        onChange={handleProjectChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="liveLink" className="form-label">Live Link</label>
                    <input
                        type="text"
                        className="form-control"
                        id="liveLink"
                        name="liveLink"
                        value={newProject.liveLink}
                        onChange={handleProjectChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="githubLink" className="form-label">GitHub Link</label>
                    <input
                        type="text"
                        className="form-control"
                        id="githubLink"
                        name="githubLink"
                        value={newProject.githubLink}
                        onChange={handleProjectChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Add Project</button>
            </form>
            <h2>My Projects</h2>
            <div className="row">
                {projects.map(project => (
                    <div key={project._id} className="col-md-4">
                        <div className="card mb-4">
                            <img src={project.images[0]} className="card-img-top" alt={project.title} />
                            <div className="card-body">
                                <h5 className="card-title">{project.title}</h5>
                                <p className="card-text">{project.description}</p>
                                <a href={project.liveLink} className="btn btn-primary" target="_blank" rel="noopener noreferrer">View Live</a>
                                <a href={project.githubLink} className="btn btn-secondary" target="_blank" rel="noopener noreferrer">View Code</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardPage;
