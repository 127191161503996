import React, { useEffect, useState } from 'react';
import API from '../utils/api';
import DOMPurify from 'dompurify';

const AboutPage = () => {
    const [bio, setBio] = useState('');

    useEffect(() => {
        const fetchBio = async () => {
            try {
                const res = await API.get('/user');
                setBio(res.data.bio);
            } catch (error) {
                console.error('Failed to fetch bio:', error);
            }
        };

        fetchBio();
    }, []);

    const removeEmptyTags = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const elements = doc.body.querySelectorAll('*');

        elements.forEach(element => {
            if (!element.innerHTML.trim()) {
                element.remove();
            }
        });

        return doc.body.innerHTML;
    };

    const createMarkup = (html) => {
        const cleanHtml = removeEmptyTags(html);
        return { __html: DOMPurify.sanitize(cleanHtml) };
    };

    return (
        <div className="container contentBodyLightContainer">
            <h1>About Me</h1>
            <div dangerouslySetInnerHTML={createMarkup(bio)} />
        </div>
    );
};

export default AboutPage;
