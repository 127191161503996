import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import API from '../utils/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UpdateProfilePage = () => {
    const { user } = useContext(AuthContext);
    const [profile, setProfile] = useState({
        fullName: '',
        phoneNumber: '',
        bio: '',
        socialLinks: {
            linkedin: '',
            twitter: '',
            facebook: '',
            github: ''
        }
    });

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const res = await API.get('/user');
                setProfile(res.data);
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        };

        fetchProfile();
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (['linkedin', 'twitter', 'facebook', 'github'].includes(name)) {
            setProfile((prevProfile) => ({
                ...prevProfile,
                socialLinks: {
                    ...prevProfile.socialLinks,
                    [name]: value
                }
            }));
        } else {
            setProfile((prevProfile) => ({
                ...prevProfile,
                [name]: value
            }));
        }
    };

    const handleBioChange = (value) => {
        setProfile((prevProfile) => ({
            ...prevProfile,
            bio: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await API.put('/user', profile);
            alert('Profile updated successfully');
        } catch (error) {
            alert('Failed to update profile');
        }
    };

    return (
        <div className="container contentBodyLightContainer">
            <h1>Update Profile</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">Full Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        value={profile.fullName}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={profile.phoneNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="bio" className="form-label">Bio</label>
                    <ReactQuill
                        theme="snow"
                        value={profile.bio}
                        onChange={handleBioChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="linkedin" className="form-label">LinkedIn</label>
                    <input
                        type="text"
                        className="form-control"
                        id="linkedin"
                        name="linkedin"
                        value={profile.socialLinks.linkedin}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="twitter" className="form-label">Twitter</label>
                    <input
                        type="text"
                        className="form-control"
                        id="twitter"
                        name="twitter"
                        value={profile.socialLinks.twitter}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="facebook" className="form-label">Facebook</label>
                    <input
                        type="text"
                        className="form-control"
                        id="facebook"
                        name="facebook"
                        value={profile.socialLinks.facebook}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="github" className="form-label">GitHub</label>
                    <input
                        type="text"
                        className="form-control"
                        id="github"
                        name="github"
                        value={profile.socialLinks.github}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Update Profile</button>
            </form>
        </div>
    );
};

export default UpdateProfilePage;