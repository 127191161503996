import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import AddProjectPage from './pages/AddProjectPage';
import UpdateProfilePage from './pages/UpdateProfilePage';
import EditProjectPage from './pages/EditProjectPage';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Footer';

const App = () => {
    return (
        <AuthProvider>
            <div id="root" className="d-flex flex-column min-vh-100">
                <Header />
                <div className="container flex-grow-1">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/add-project" element={<PrivateRoute><AddProjectPage /></PrivateRoute>} />
                        <Route path="/edit-project/:id" element={<PrivateRoute><EditProjectPage /></PrivateRoute>} />
                        <Route path="/update-profile" element={<PrivateRoute><UpdateProfilePage /></PrivateRoute>} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </AuthProvider>
    );
};

export default App;
