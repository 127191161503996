import { createContext, useState, useEffect } from 'react';
import API, { setAuthToken } from '../utils/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthToken(token);
            fetchUserProfile();
        }
    }, []);

    const fetchUserProfile = async () => {
        try {
            const res = await API.get('/user');
            setProfile(res.data);
        } catch (error) {
            console.error('Failed to fetch profile:', error);
        }
    };

    const login = async (username, password) => {
        try {
            const res = await API.post('/auth/login', { username, password });
            const { token } = res.data;
            localStorage.setItem('token', token);
            setAuthToken(token);
            setUser({ username }); // Assuming the response contains username
            fetchUserProfile();
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuthToken(null);
        setUser(null);
        setProfile(null);
    };

    return (
        <AuthContext.Provider value={{ user, profile, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
