import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import API from '../utils/api';
import AuthContext from '../context/AuthContext';
import LazyLoad from '../components/LazyLoad';

const HomePage = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, profile } = useContext(AuthContext);

    const fetchProjects = async () => {
        try {
            const res = await API.get('/projects');
            const sortedProjects = res.data.sort((a, b) => b.buildYear - a.buildYear);
            setProjects(sortedProjects);
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        } finally {
            setLoading(false);
        }
    };

    const deleteProject = async (id) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            try {
                await API.delete(`/projects/${id}`);
                setProjects(projects.filter(project => project._id !== id));
                alert('Project deleted successfully');
            } catch (error) {
                console.error('Failed to delete project:', error);
            }
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <div className="container contentBodyContainer">
            <h1>Projects</h1>
            {loading ? (
                <p>Loading projects...</p>
            ) : projects.length === 0 ? (
                <div>
                    <i className="fa-thin fa-folder"></i>
                    <h3>No Projects here!</h3>
                    <p>Get started by logging in and adding your projects to your portfolio!</p>
                </div>
            ) : (
                <div className="row">
                    {projects.map(project => (
                        <LazyLoad key={project._id}>
                            <ProjectCard project={project} deleteProject={deleteProject} user={user} />
                        </LazyLoad>
                    ))}
                </div>
            )}
        </div>
    );
};

const ProjectCard = ({ project, deleteProject, user }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    return (
        <div className="col-md-4">
            <div className="card mb-4">
                {project.images && project.images.length > 0 && (
                    <img
                        src={`https://anthamuse.plygnd.me${project.images[0]}`}
                        className="card-img-top"
                        alt={project.title}
                    />
                )}
                <div className="card-body">
                    <h5 className="card-title">{project.title}</h5>
                    <p className="buildYearSubtitle">{project.buildYear}</p>
                    <p className={`card-text ${showFullDescription ? '' : 'line-clamp'}`}>
                        {project.description}
                    </p>
                    {project.description.length > 80 && (
                        <a onClick={toggleDescription} className="btn btn-dark" rel="noopener noreferrer">
                            {showFullDescription ? 'Read Less ▲' : 'Read More ▼'}
                        </a>
                    )}
                    {project.liveLink && (
                        <a href={project.liveLink} className="btn btn-primary" target="_blank" rel="noopener noreferrer">View Live Project</a>
                    )}
                    {project.githubLink && (
                        <a href={project.githubLink} className="btn btn-secondary" target="_blank" rel="noopener noreferrer">View GitHub</a>
                    )}
                    {user && (
                        <>
                            <Link to={`/edit-project/${project._id}`} className="btn btn-warning">Edit</Link>
                            <button className="btn btn-danger" onClick={() => deleteProject(project._id)}>Delete</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HomePage;