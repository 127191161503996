import React, { useState } from 'react';
import API from '../utils/api';
import Resizer from 'react-image-file-resizer';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

const AddProjectPage = () => {
    const [newProject, setNewProject] = useState({
        title: '',
        buildYear: '',
        images: [],
        description: '',
        liveLink: '',
        githubLink: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewProject((prevProject) => ({
            ...prevProject,
            [name]: value
        }));
    };

    const onDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            Resizer.imageFileResizer(
                file,
                1280,
                1280,
                'JPEG',
                100,
                0,
                uri => {
                    setNewProject((prevProject) => ({
                        ...prevProject,
                        images: [...prevProject.images, uri]
                    }));
                },
                'blob'
            );
        });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', newProject.title);
        formData.append('buildYear', newProject.buildYear);
        formData.append('description', newProject.description);
        formData.append('liveLink', newProject.liveLink);
        formData.append('githubLink', newProject.githubLink);

        newProject.images.forEach((image, index) => {
            formData.append('images', image, `image-${index}.jpeg`);
        });

        try {
            await API.post('/projects', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Project added successfully');
            setNewProject({
                title: '',
                buildYear: '',
                images: [],
                description: '',
                liveLink: '',
                githubLink: ''
            });
        } catch (error) {
            alert('Failed to add project');
            console.error('Error:', error.response || error.message);
        }
    };

    return (
        <div className="container contentBodyLightContainer">
            <h1>Add Project</h1>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="title">Title</Form.Label>
                            <Form.Control
                                type="text"
                                id="title"
                                name="title"
                                value={newProject.title}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="buildYear">Build Year</Form.Label>
                            <Form.Control
                                type="number"
                                id="buildYear"
                                name="buildYear"
                                value={newProject.buildYear}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="description">Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                id="description"
                                name="description"
                                value={newProject.description}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="liveLink">Live Link</Form.Label>
                            <Form.Control
                                type="text"
                                id="liveLink"
                                name="liveLink"
                                value={newProject.liveLink}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="githubLink">GitHub Link</Form.Label>
                            <Form.Control
                                type="text"
                                id="githubLink"
                                name="githubLink"
                                value={newProject.githubLink}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Images</Form.Label>
                            <div {...getRootProps({ className: 'dropzone' })} className="dropzone">
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            <div className="selected-images">
                                {newProject.images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={URL.createObjectURL(image)}
                                        alt={`Selected ${index}`}
                                        className="img-thumbnail mt-2"
                                        style={{ maxHeight: '100px' }}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" className="btn btn-primary">Add Project</Button>
            </Form>
        </div>
    );
};

export default AddProjectPage;
